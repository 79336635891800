.user-banner {
    // background-image: url(../images/Banner.png);
    background-position: center;
    height: 192px;
    background-repeat: no-repeat;
    padding: calc(var(--bs-gutter-x, 1rem) * 2.5);
    background-size: cover;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    a,
    p {
        color: inherit;
    }
}

.iq-col-masonry {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
}

.iq-incoming-blogs {
    .iq-blog-img {
        width: 100%;
        height: 14rem;
        object-fit: cover;
        border-radius: $border-radius;
    }
}

.iq-upcoming-blogs {
    >img {
        width: 16rem;
        height: 12rem;
    }
}

.iq-trending-blog {
    height: 10.5rem;
}

.iq-top-categories {
    width: 100%;
    height: 8.45rem;
    border-radius: $border-radius;
    margin-bottom: $spacer;
}

.iq-blog-experience {
    height: 25rem;
}

.iq-recent-post {
    height: 17rem;
}

.input-group-2 {
    .form-control {
        border: none;
        border-bottom: 1px solid var(--bs-border-color);
        padding-right: 2.5rem;

        &:focus {
            box-shadow: unset !important;
        }
    }

    span {
        position: absolute;
        top: 0.375rem;
        right: 0.625rem;
        z-index: 10;
    }
}

.iq-blog-adventure {
    color: $gray-600;

    &:hover {
        color: var(--#{$variable-prefix}primary) !important;
    }
}

.blog-line-around {
    border-bottom: 1px solid var(--bs-border-color);
    text-align: center;
    line-height: 0;

    .blog-line-day {
        background: var(--bs-body-bg);
    }
}

.iq-blog-categories {
    .iq-categories-name {
        width: 40%;
        font-weight: 500 !important; //added this to overright the css of page.scss
    }

    .iq-categories-indicator {
        border-bottom: 1px solid var(--bs-border-color);
        line-height: 0;
    }
}

//Media Query
@include media-breakpoint-down(xl) {
    .iq-incoming-blogs {
        >img {
            width: 26rem;
        }
    }
}

@include media-breakpoint-down(lg) {
    .iq-top-categories {
        height: 15rem;
    }
}

@include media-breakpoint-down(md) {
    .iq-blog-experience {
        height: 10rem;
    }
}

@media (max-width:650px) {

    .iq-upcoming-blogs,
    .iq-incoming-blogs {
        flex-direction: column;

        >img {
            width: 100%;
        }
    }
}