/* Masquer la flèche du dropdown */
.dropdown-action-btn::after {
  display: none !important;
}

/* .dropdown-menu-action {
  position: absolute;
  z-index: 4000;
  right: 0;
  left: auto;
} */
